import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import iconOrange from "../images/icon-orange.png"
import technologyImage from "../images/technologyrace.jpg"
import tsunamiImage from "../images/financialtsunami.jpg"
import providerImage from "../images/providervalue.jpg"
import dialogueImage from "../images/opendialogue.jpg"
import ecosystemImage from "../images/ecosystem.jpg"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Coolcarousel from "../components/coolcarousel"
import Coolcards from "../components/coolcards"
import Coolcards2 from "../components/coolcards2"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <div class="container-flex">
        <Coolcarousel />
      </div>
      <div class="container">
        <div class="row mt-5">
          <Coolcards />
        </div>
      </div>

      <div class="container">
        <div class="row mt-2">
          <Coolcards2 />
        </div>
      </div>

      <div class="container br-cool">
        <div class="row">
          <div class="col mt-3">
            <h1 class="text-center text-dark">Latest Videos</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-xl mt-3 mb-3">
            <div class="card h-100 border-0 bg-coolorange coolsmallshadow">
              <div class="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/bD5Vvp7uWEM?controls=0"></iframe>
              </div>
              <div class="card-body">
                <h5 class="card-title">
                  Sustainable relocation: why global mobility must take action
                </h5>
                <p class="card-text">
                  Environmental and social sustainability are no longer 'nice to
                  haves' for any business, they are now urgent and central to
                  future success in the global mobility industry. Join Ben Ivory
                  from Graebel Companies, Inc.; René Stegmann from Relocation
                  Africa; Derrick Young from BGRS;{" "}
                  <b>Paul Barnes from Inspire Global Mobility Consulting;</b>{" "}
                  Jessica Deutschmann from Gosselin Group and Dominic Weaver,
                  FIDI Focus editor, as they discuss why sustainability has to
                  become a central focus for global mobility, the progress made
                  to date, and where relo must focus its attention on change -
                  one practical step after the other.
                </p>

                <p>
                  Check out the feature article on the <b>FIDI</b> website{" "}
                  <a
                    class="link-text text-decoration-underline"
                    href="https://www.fidifocus.org/sustainability/making-a-difference-how-to-make-moving-sustainable/"
                  >
                    HERE
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl mt-3 mb-3">
            <div class="card h-100 border-0 bg-coolorange coolsmallshadow">
              <div class="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/V85aCP5F8Mg?controls=0"></iframe>
              </div>
              <div class="card-body">
                <h5 class="card-title">Sustainability In Mobility</h5>
                <p class="card-text">
                  Paul Barnes of Inspire Global Mobility is the primary trainer
                  on our new SIM+ Module, "Sustainability in Mobility". In this
                  interview from the module, he talks with Circular Economy
                  expert John Holm, VP of Strategic Initiatives at PYXERA Global
                  about the Circular Economy and how it can help all aspects of
                  the Global Mobility world from mobility programs, polices and
                  the services.
                </p>
                <p class="text-start card-text">
                  Global mobility and our wider world needs to embrace
                  circularity and move away from the traditional Take, Make,
                  Use, Dispose cycle which is so damaging to our world. If you,
                  are you serious about making a difference and increasing
                  sustainability then this introduction to the Circular Economy
                  will be very useful to you.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl mt-3 mb-3">
            <div class="card h-100 border-0 bg-coolorange coolsmallshadow">
              <div class="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/0abdRuOJ59w?controls=0"></iframe>
              </div>
              <div class="card-body">
                <h5 class="card-title">
                  LOVE+RELO: Paul Barnes, Director, Global Mobility RFP
                  Consulting
                </h5>
                <p class="card-text">
                  Paul Barnes, Director, Inspire Global Mobility Consulting is
                  on the show! We talk about how corporations can position their
                  RFPs for success also technology in mobility: Is it really
                  that important? We chat about the increasing pressure on
                  Global Mobility to increase sustainability, a subject Paul is
                  passionate about.
                </p>
              </div>
            </div>
          </div>

          <div class="col-xl mt-3 mb-3">
            <div class="card h-100 border-0 bg-coolorange coolsmallshadow">
              <div class="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/EH-Z8UnU8cQ?controls=0"></iframe>
              </div>
              <div class="card-body">
                <h5 class="card-title">
                  Paul and Ben 5 reasons RFPs are Broken
                </h5>
                <p class="card-text">
                  There's a ton of bad mobility RFPs out there. Paul Barnes and
                  Ben Heller discuss the top problems and solutions to help
                  mobility and procurement teams thrive in the process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container br-cool pb-4">
        <div class="row">
          <div class="col border-secondary">
            <h1 class="text-center mt-3">Latest Blogs</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-xl">
            <div class="card mt-4 coolsmallshadow card-hovereffect">
              <img src={technologyImage} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title mt-2">Technology Race To Where!</h5>
                <Link to="/blog1" className="stretched-link link-text">
                  It is a hard world out there for Relocation companies with so
                  many decisions to take and cashflow challenges. These
                  companies are run by smart people with an intelligent
                  workforce so they should be well placed to move with the
                  times. But tough decisions need to be taken on their future
                  direction. A technology race has been ongoing for many years,
                  where the one with the latest upgrades gets ahead of the
                  others. But where will this race end? In fame and glory or
                  destruction?
                </Link>
                <p class="card-text mt-4">@Paul Barnes</p>
              </div>
            </div>

            <div class="card mt-4 coolsmallshadow card-hovereffect">
              <img src={tsunamiImage} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title mt-2">Catastrophic Financial Tsunami!</h5>
                <Link to="/blog2" className="stretched-link link-text">
                  The relocation market has been stressed financially like no
                  one could imagine prior to COVID. This is after years of
                  pressure on profits, increased compliance, and investment in
                  areas such as technology?
                </Link>
                <p class="card-text mt-4">@Paul Barnes</p>
              </div>
            </div>
          </div>

          <div class="col-xl">
            <div class="card mt-4 coolsmallshadow card-hovereffect">
              <img src={ecosystemImage} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title mt-2">Ecosystem the future!</h5>
                <Link to="/blog5" className="stretched-link link-text">
                  Our Global Mobility world is complex with so many moving parts
                  in a mobility program. This is why ‘One stop shop’ relocation
                  companies have evolved to support such service needs from
                  destination services, household goods moving, expense
                  management through to immigration and compensation services.
                  These relocation management providers identified the needs of
                  multinational companies and provided the benefits of only
                  having to contract with one provider across a wide spectrum of
                  services.
                </Link>
                <p class="card-text mt-4">@Paul Barnes</p>
              </div>
            </div>
          </div>

          <div class="col-xl">
            <div class="card mt-4 coolsmallshadow card-hovereffect">
              <img src={dialogueImage} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title mt-2">
                  Open dialogue and Not Surprises!
                </h5>
                <Link to="/blog4" className="stretched-link link-text">
                  Are your providers good at communicating and sharing how their
                  company is doing in these challenging times? Are you welcoming
                  of their communication and see value in such dialogue?
                  <br />
                  <br />
                  If the answer is no, then think carefully about engaging in
                  dialogue with your provider as no news is not good news in
                  these challenging times!
                </Link>
                <p class="card-text mt-4">@Paul Barnes</p>
              </div>
            </div>

            <div class="card mt-4 coolsmallshadow card-hovereffect">
              <img src={providerImage} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title mt-2">
                  Do Your Providers Really Add Value?
                </h5>
                <Link to="/blog3" className="stretched-link link-text">
                  Obviously, an important question! Does your Relocation service
                  provider truly do everything they can to deliver the best
                  service, evolve your program, and make your life easier?
                </Link>
                <p class="card-text mt-4">@Paul Barnes</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
