import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Coolcard from "../components/coolcard"

const Coolcards = () => {
  const assetQuery = useStaticQuery(graphql`
    {
      allContentfulCustomCard(sort: { fields: orderno }) {
        edges {
          node {
            title
            content
            linkId
            orderno
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)
  const cards = assetQuery.allContentfulCustomCard.edges.map(x => x.node)

  return cards.map(card => (
    <Coolcard
      id={card.title}
      title={card.title}
      content={card.content}
      linkid={card.linkId}
      image={card.image.file.url}
    />
  ))
}

export default Coolcards
