import * as React from "react"
import { Link } from "gatsby"

const Coolcard = props => {
  const { title, content, image, linkid } = props
  return (
    <div class="col-md mb-2 card-hovereffect">
      <Link to={"/resources#" + linkid} className="link-text">
        <div class="card h-100 coolsmallshadow">
          <img src={image} class="card-img-top" alt="..." />
          <div class="card-body text-center">
            <h5 class="card-title">{title}</h5>
            <p class="card-text">{content}</p>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Coolcard
