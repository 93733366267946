import * as React from "react"

const Coolcarousel = () => {
  return (
    <div
      id="carousel1"
      class="carousel slide coolshadow"
      data-bs-ride="carousel"
      data-bs-interval="4000"
    >
      <div class="carousel-inner">
        <div class="carousel-item active carouselbackground1">
          <div
            class="mask"
            style={{ "background-color": "rgba(0, 0, 0, 0.4)" }}
          >
            <div class="d-flex justify-content-center align-items-center h-100">
              <div class="text-white text-center">
                <h1 class="">Complex Made Simple</h1>
                <h5>
                  Optimise your tender (PQQ, RFI, RFP, RFQ) strategy and
                  management to achieve your goals
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item carouselbackground2">
          <div
            class="mask"
            style={{ "background-color": "rgba(0, 0, 0, 0.1)" }}
          >
            <div class="d-flex justify-content-center align-items-center h-100">
              <div class="text-white text-center">
                <h1 class="">Sustainability</h1>
                <h5>
                  Fostering collaboration and steering transformative thinking,
                  practices, and policies towards a sustainable and positive
                  future
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item carouselbackground3">
          <div class="mask" style={{ "background-color": "rgba(0, 0, 0, 0)" }}>
            <div class="d-flex justify-content-center align-items-center h-100">
              <div class="text-white text-center">
                <h1 class="">How Is Your Provider Performing</h1>
                <h5>
                  How to maximise value opportunities and achieve cost savings
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item carouselbackground4">
          <div
            class="mask"
            style={{ "background-color": "rgba(0, 0, 0, 0.4)" }}
          >
            <div class="d-flex justify-content-center align-items-center h-100">
              <div class="text-white text-center">
                <h1 class="">Technology & Innovation</h1>
                <h5>
                  Leverage the value of Technology and Innovation to enhance
                  your program Insight to improve and achieve your goals
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item carouselbackground5">
          <div
            class="mask"
            style={{ "background-color": "rgba(0, 0, 0, 0.4)" }}
          >
            <div class="d-flex justify-content-center align-items-center h-100">
              <div class="text-white text-center">
                <h1 class="">Education</h1>
                <h5>Insight to optimise your program</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carousel1"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carousel1"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  )
}

export default Coolcarousel
